// insert trained custom models pairs with their category ID here.

// Data Format:
// Model Type -- src language -- target language -- category ID (for API call)  *** THIS NEEDS TO BE UPDATED ***

// For each model type make sure to include an object with all empty values first, check below.
// This is to ensure an empty selection from the dropdown menus

const trainedModelPairs = [
  {
    "model type": "",
    "categoryID": "",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      }
    ]
  },
  // Note: only below model with EN,SP will work in QA Custom Translator Resource
  {
    "model type": "Lilly Global (General)",
    // Used for Qa, but no custom models are set up in Qa yet
    // "categoryID": "cad23efe-5c2d-4c2e-9010-b486a5f4ed30-GENERAL",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-GENERAL", // TODO: Consider putting these in .env file for additional obfuscation
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "German", "tcode": "de"},
          {"tname": "French", "tcode": "fr"},
          {"tname": "Italian", "tcode": "it"},
          {"tname": "Japanese", "tcode": "ja"},
          {"tname": "Portuguese (Brazil)", "tcode": "pt"},
          {"tname": "Chinese (Simplified)", "tcode": "zh-Hans"},
          {"tname": "Spanish (Latin)", "tcode": "es"}
        ]
      },
      {
        "sname": "German",
        "scode":"de",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "French",
        "scode":"fr",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Italian",
        "scode":"it",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Japanese",
        "scode":"ja",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Portuguese (Brazil)",
        "scode":"pt",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Chinese (Simplified)",
        "scode":"zh-Hans",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Spanish (Latin)",
        "scode":"es",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }
    ]
  },

  {
    "model type": "Ethics & Compliance",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-EC-CUSTOM",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "Chinese (Simplified)", "tcode": "zh-Hans"},
          {"tname": "Chinese (Traditional)", "tcode": "zh-Hant"},
          {"tname": "Czech", "tcode": "cs"},
          {"tname": "French", "tcode": "fr"},
          {"tname": "French (Canada)", "tcode": "fr-ca"},
          {"tname": "German", "tcode": "de"},
          {"tname": "Greek", "tcode": "el"},
          {"tname": "Hungarian", "tcode": "hu"},
          {"tname": "Italian", "tcode": "it"},
          {"tname": "Japanese", "tcode": "ja"},
          {"tname": "Korean", "tcode": "ko"},
          {"tname": "Polish", "tcode": "pl"},
          {"tname": "Portuguese", "tcode": "pt-pt"},
          {"tname": "Portuguese (Brazil)", "tcode": "pt"},
          {"tname": "Slovak", "tcode": "sk"},
          {"tname": "Spanish (Latin)", "tcode": "es"},
          {"tname": "Spanish (Spain)", "tcode": "es-xn"},
          {"tname": "Turkish", "tcode": "tr"}
        ]
      },
      {
        "sname": "Chinese (Simplified)",
        "scode":"zh-Hans",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Chinese (Traditional)",
        "scode":"zh-Hant",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "French",
        "scode":"fr",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "German",
        "scode":"de",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Greek",
        "scode":"el",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Hungarian",
        "scode":"hu",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Italian",
        "scode":"it",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Japanese",
        "scode":"ja",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Korean",
        "scode":"ko",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Polish",
        "scode":"pl",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Portuguese (Brazil)",
        "scode":"pt",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Slovak",
        "scode":"sk",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Spanish (Latin)",
        "scode":"es",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Spanish (Spain)",
        "scode":"es-xn",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Turkish",
        "scode":"tr",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }
    ]
  },

  {
    "model type": "Enterprise Brand Websites",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-EnterpriseBrandSites-INTERNT",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "Italian", "tcode": "it"},
          {"tname": "Spanish (Latin)", "tcode": "es"}
        ]
      },
      {
        "sname": "Italian",
        "scode":"it",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Spanish (Latin)",
        "scode":"es",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }
    ]
  },

  {
    "model type": "Medicines Development Unit (MDU)",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-MDU-HEALTHC",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "Japanese", "tcode": "ja"}
        ]
      },
      {
        "sname": "Japanese",
        "scode":"ja",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }
    ]
  },

  {
    "model type": "Marketing",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-MKTNG-TELECOM",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "Spanish (Latin)", "tcode": "es"}
        ]
      }/*,
      {
        "sname": "Spanish (Latin)",
        "scode":"es",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }*/
    ]
  },

  {
    "model type": "ChatNow",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-CHATNOW-SPEECH",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "German", "tcode": "de"},
          {"tname": "Japanese", "tcode": "ja"},
          {"tname": "Italian", "tcode": "it"},
          {"tname": "French", "tcode": "fr"},
          {"tname": "Portuguese", "tcode": "pt"},
          {"tname": "Spanish (Latin)", "tcode": "es"},
          {"tname": "Chinese (Simplified)", "tcode": "zh-Hans"},
        ]
      },
      {
        "sname": "Chinese (Simplified)",
        "scode":"zh-Hans",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }
    ]
  },

  {
    "model type": "Protocols",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-PWC-MEDICIN",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "Chinese (Simplified)", "tcode": "zh-Hans"},
          {"tname": "Japanese", "tcode": "ja"}
        ]
      }
    ]
  },

  {
    "model type": "Common Technical Document (CTD) POC",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-CTD-MEDICIN",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "Japanese", "tcode": "ja"}
        ]
      }
    ]
  },

  // Update for M&Q
  {
    "model type": "Manufacturing & Quality - Product Complaints",
    "categoryID": "12b40ab5-7a7e-40b3-833e-cb9e0dcfc8a6-GENERAL",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // Spanish -> ...  English
        "sname": "Spanish (Latin America)",
        "scode":"es",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      { // Spanish -> ...  English
        "sname": "Spanish (Spain)",
        "scode":"none",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      { // Japanese -> ...  English
        "sname": "Japanese",
        "scode":"ja",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      { // German -> ...  English
        "sname": "German",
        "scode":"de",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      { // Portuguese (Brazil) -> ...  English
        "sname": "Portuguese (Brazil)",
        "scode":"pt",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      { // French -> ...  English
        "sname": "French",
        "scode":"fr",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }
    ]
  }

  // // Demo model for presentations
  // {
  //   "model type": "Demo",
  //   "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-demo-CUSTOM",
  //   "models": 
  //   [
  //     { // Blank Entry
  //       "sname": "",
  //       "scode":"",
  //       "target langs": 
  //       [
  //         {"tname": "", "tcode": ""}
  //       ]
  //     },
  //     { // English -> ...  Spanish
  //       "sname": "English",
  //       "scode":"en",
  //       "target langs": 
  //       [
  //         {"tname": "", "tcode": ""},
  //         {"tname": "Spanish", "tcode": "es"}
  //       ]
  //     },
  //     { // Spanish -> ...  English
  //       "sname": "Spanish",
  //       "scode":"es",
  //       "target langs": 
  //       [
  //         {"tname": "", "tcode": ""},
  //         {"tname": "English", "tcode": "en"}
  //       ]
  //     }
  //   ]
  // }
]

export default trainedModelPairs;